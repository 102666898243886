:root[data-theme='light'] {
  color-scheme: light;
}
:root[data-theme='dark'] {
  color-scheme: dark;
}

* {
  font-family: Roboto, sans-serif;
}

html,
body,
#root {
  min-width: 340px;
}

:focus-visible {
  outline: none;
}

// Remove the Edge password reveal button
// https://learn.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
::-ms-reveal {
  display: none;
}

.react-time-picker__wrapper {
  border: 1;
  border-color: rgb(230, 230, 230);
  background-color: rgb(230, 230, 230);
  border-radius: 8px;
  padding: 8px;
}
